@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.container {
  @include desktop {
    flex: 0 0 50%;
    order: -1;
    max-width: 50%;
    margin-bottom: rem(72);
  }

  @include mobile {
    order: 13;
  }

  display: flex;
  flex-direction: column;
}

.mainPromise {
  @include mobile {
    margin-top: rem(20);
  }
}

.mainSearch {
  @include mobile {
    position: relative;
    margin-top: rem(20);
  }
}
