@import 'abstracts/variables';
@import 'abstracts/mixins';
@import 'abstracts/functions';

.linksSection {
  @include mobile {
    padding: 0 rem(37) rem(55) rem(37);
  }

  padding-bottom: rem(40);
}

.guidesLinkTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: rem(6) 0;
  border-bottom: rem(1) solid $brown;
  margin-bottom: 0 !important;
  font-size: rem(14) !important;
  text-align: left;

  strong {
    color: $brown;
    text-transform: capitalize;
  }

  span {
    color: $red;
    font-size: rem(25);
    line-height: rem(17);
  }

  p {
    max-width: rem(218);
    margin-bottom: 0;
    margin-top: rem(8);
    line-height: rem(16.8);
  }
}

.guidesLink {
  font-size: rem(14);
}

.allLinks {
  color: $brown;
  font-weight: bold;
  font-size: rem(14);

  &:hover {
    color: $red;
    text-decoration: underline;
  }
}

.title {
  @include mobile {
    margin-top: 0;
  }

  padding-bottom: rem(12);
  border-bottom: rem(2) solid $brown;
  margin-top: rem(32);
  margin-bottom: 0;
  color: $red;
  font-weight: bold;
  font-size: rem(16);
  text-transform: uppercase;
}
