@import 'abstracts/functions';
@import 'abstracts/mixins';

.mainHeaderMobileWrapper {
  @include mobile {
    display: flex;
    flex-direction: column-reverse;
  }
}

.mainHeader {
  @include mobile {
    flex-direction: column;
    margin-top: rem(10);
  }

  display: flex;
  margin-left: auto;
  margin-right: auto;
}

@include desktop-md {
  .mainHeader {
    max-width: rem(1007);
  }
}

@include desktop-l {
  .mainHeader {
    max-width: rem(1349);
  }
}

@include desktop-xl {
  .mainHeader {
    max-width: rem(1890);
  }
}

.mainMobileSearch {
  @include mobile {
    position: relative;
    display: block;
    margin-top: rem(20);
  }

  display: none;
}

.blackSection {
  background-color: $dark-1;
}
