@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.container {
  @include mobile {
    display: none;
  }

  @include desktop-l {
    padding-right: rem(70);
  }

  @include desktop-xl {
    justify-content: center;
    padding-right: rem(14);
    margin-top: rem(44);
  }

  position: relative;
  display: flex;
  flex: 0 0 50%;
  align-items: flex-end;
  flex-direction: column;
  margin: rem(27) 0;
}

.promiseImage {
  @include desktop-xl {
    display: block;
  }

  position: absolute;
  left: rem(-60);
  top: rem(-37);
  z-index: -1;
  display: none;
  width: rem(332);
  height: rem(400);
}

.innerContainer {
  @include desktop-xl {
    max-width: rem(732);
  }

  max-width: rem(558);
}

.searchSection {
  padding: rem(20);
  border-top-left-radius: rem(4);
  border-top-right-radius: rem(4);
  background-color: $brown;
}

.feedbackSection {
  display: flex;
  justify-content: space-between;
}

.searchTitle {
  @include desktop-l {
    max-width: 50%;
  }

  max-width: 55%;
  color: $light;
  font-weight: 500;
  font-size: rem(14);
  line-height: rem(17);
  text-align: left;
  text-transform: uppercase;
}

.searchFeedback {
  @include desktop {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }
}

.searchCritizr {
  justify-content: flex-end;

  div {
    @include desktop {
      flex-direction: column;
      align-items: flex-end !important;
    }

    flex-direction: row-reverse;
  }
}

.searchEngine {
  display: flex;
  width: 100%;
  height: rem(42);
}

.searchInput {
  flex: 1;
  padding-left: rem(14);
  border: 0;
  border-top-left-radius: rem(4);
  border-bottom-left-radius: rem(4);
  outline: 0;
}

.searchButton {
  width: rem(179);
  padding: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none !important;

  span {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}

.searchButtonLabel {
  font-weight: 500;
  font-size: rem(14);
}

.services {
  @include desktop-l-and-bigger {
    padding-top: rem(20);
    padding-bottom: rem(20);
  }

  padding-top: rem(15);
  padding-bottom: rem(15);
}
