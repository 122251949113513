@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.services {
  @include flex-grid-items(2, 16);

  justify-content: center;
  width: 100%;
  padding: rem(20) 0;
  background: $gray-light-3-1;
  row-gap: rem(12);
}

.service {
  @include desktop-xl {
    width: rem(335);
  }

  @include desktop-md {
    width: rem(207);

    &:nth-child(4),
    &:nth-child(5) {
      display: none;
    }
  }

  display: flex;
  align-items: center;
  width: rem(251);
  height: rem(62);
  border: rem(1) solid $gray-dark-9;
  border-radius: rem(5);
  background-color: $light;
  cursor: pointer;
}

.serviceIconWrapper {
  display: flex;
  justify-content: center;
  width: rem(66);
}

.serviceIcon {
  svg {
    fill: $red !important;
  }

  polygon,
  path {
    fill: inherit;
  }
}

.serviceTitle {
  flex: 1;
  font-weight: 500;
  line-height: rem(17);
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

.rightArrow {
  width: rem(24);
}
