@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.stickyCta {
  @include mobile {
    width: 100%;
  }

  @include desktop {
    right: rem(169);
    border-radius: rem(20) rem(20) 0 0;
  }

  padding: rem(10) rem(20);
  background-color: $dark;
}

.stickyCtaButton,
.stickyCtaTitle {
  @include desktop {
    display: inline-block;
    vertical-align: middle;
  }
}

.stickyCtaTitle {
  @include mobile {
    display: none;
  }

  @include desktop {
    margin-right: rem(10);
    margin-bottom: 0;
    color: $light;
    font-size: rem(13);
    line-height: normal;
    text-transform: none;
  }
}

.stickyCtaButton {
  @include mobile {
    display: block;
    margin: 0 auto;
  }

  padding: rem(10);
  border-radius: rem(30);
  font-weight: 400;
}
