@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.expertAdvice {
  @include mobile {
    padding: 3rem rem(14) 0 rem(14);
  }

  @include desktop {
    padding: rem(48) rem(24) rem(48);
  }

  margin: auto;
  background-color: $gray-light;
}

.expertAdviceImage {
  @include mobile {
    height: rem(142);
    min-width: rem(121);
  }

  @include desktop-md {
    height: rem(205);
    min-width: rem(174);
    margin: rem(-40) 0 rem(-10);
  }

  @include desktop-l-and-bigger {
    height: rem(280) - rem(30);
    width: rem(238) - rem(30);
  }

  position: relative;
}

.expertAdviceHeader {
  @include mobile {
    margin-bottom: rem(13);
    margin-left: rem(-14);
  }

  @include desktop {
    flex-wrap: wrap;
  }

  @include desktop-l-and-bigger {
    flex-wrap: nowrap;
    margin-right: 4rem;
  }

  display: flex;
  align-items: center;
}

.headerWrapper {
  @include desktop {
    flex: 1;
    align-self: flex-end;
    padding: rem(40) 0;
  }

  @include desktop-md {
    padding: 0;
  }
}

.headerTitle {
  @include mobile {
    font-size: rem(24);
    line-height: rem(28);
  }

  @include desktop {
    position: relative;
  }

  @include desktop-md {
    max-width: rem(157);
    font-size: rem(25);
    line-height: rem(25);
  }

  @include desktop-l-and-bigger {
    margin-bottom: rem(30);
    font-size: rem(34);
    line-height: rem(34);
  }

  color: $red;
  font-weight: 700;
  letter-spacing: rem(0.82);
  text-transform: uppercase;
  opacity: 1;
}

.allAdvicesBtn {
  @include mobile {
    display: none;
  }

  @include desktop {
    display: inline;
  }
}

.contentContainer {
  align-self: center;
}

.expertAdviceContent {
  @include mobile {
    padding: rem(32) rem(22);
  }

  @include desktop {
    min-height: 20rem;
    padding: rem(36) rem(34);
  }

  @include desktop-l-and-bigger {
    margin-left: 2rem;
  }

  position: relative;
  border: rem(1) solid $brown;
  border-radius: rem(4);
}

.contentTitle {
  @include mobile {
    top: rem(-12);
    left: rem(13);
    font-size: rem(17);
    line-height: rem(22);
    white-space: nowrap;
  }

  @include desktop {
    top: rem(-15);
    left: rem(23);
    font-size: rem(24);
    line-height: rem(27);
  }

  position: absolute;
  padding: 0 rem(10);
  color: $brown;
  font-weight: 300;
  text-transform: initial;
  background-color: $gray-light;
}

.contentSubtitle {
  @include mobile {
    line-height: rem(19);
  }

  margin-bottom: rem(21);
  color: $red;
  font-weight: 700;
  font-size: rem(20);
  line-height: rem(24);
  text-align: left !important;
  text-transform: initial !important;
}

.contentBtn {
  @include mobile {
    position: absolute;
    bottom: 0;
    left: 50%;
    background-color: $gray-light;
    transform: translate(-50%, 50%);
  }

  @include desktop {
    display: none;
  }
}

.contentText {
  position: relative;
  overflow: hidden;
  height: rem(186);
  margin-bottom: rem(5);
}

.opacity {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 15%;
  margin-bottom: 0;
  background-image: linear-gradient(
    to bottom,
    rgba($gray-light, 70%),
    $gray-light
  );
}

.imgCol {
  justify-content: center;
  align-items: center;
  display: flex;
}

.otherThemes {
  @include mobile {
    padding: 0;
    margin: 0;
    margin-top: 3rem;
  }
}
