@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.contact {
  @include mobile {
    display: none;
  }

  @include desktop {
    position: fixed;
    top: 88vh;
    right: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    height: rem(42);
    padding: rem(5) rem(10) rem(5) rem(2);
    border-top-left-radius: rem(42);
    border-bottom-left-radius: rem(42);
    color: $light;
    font-size: rem(14);
    line-height: rem(42);
    text-decoration: none;
    background-color: $blue;
  }
}

.contact:hover {
  @include desktop {
    color: $light;
  }
}

.contactLabel {
  @include desktop {
    margin-left: rem(5);
  }
}
