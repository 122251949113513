@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.stickyComponent {
  position: fixed;
  bottom: 0;
  z-index: 99;
  transition: bottom, 0.5s linear;
}

.stickyComponentHidden {
  bottom: rem(-200);
}
