@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.promise {
  @include desktop-xl {
    padding: rem(67) 0 rem(54) rem(106);
  }
}

.promiseText {
  @include mobile {
    font-size: rem(18);
    line-height: rem(25);
  }

  @include desktop-md {
    font-size: rem(21);
    line-height: rem(25);
  }

  @include desktop-l {
    font-size: rem(29);
    line-height: rem(34);
  }

  @include desktop-xl {
    font-size: rem(35);
    line-height: rem(42);
  }

  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}

.promiseTextRed {
  color: $red;
}

.promiseTextBrown {
  color: $brown;
}
