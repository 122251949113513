@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.bannersContainer {
  @include desktop {
    flex: 0 0 50%;
    order: 12;
    max-width: 50%;
    padding-right: rem(15);
    padding-left: rem(15);
    margin-top: rem(27);
  }

  max-width: rem(814);
}

.banners {
  @include mobile {
    width: 100%;
    max-width: 100%;
  }

  @include desktop-md {
    max-width: rem(473);
  }

  @include desktop-l {
    max-width: rem(806);
  }

  position: relative;
  height: 100%;
}

.bannersCarousel {
  box-shadow: -0.1143rem 0.1143rem 0.4143rem $gray-shaddow;
}

.bannersNav {
  @include mobile {
    display: none !important;
  }

  position: absolute;
  top: 100%;
  width: 102%;
  margin: 0 1% 0 -1%;
}

.bannersNav :global(li.__cloned) {
  display: none;
}

.bannersNav :global(ul) {
  transform: none !important;
}

.bannersNav ul {
  display: flex;
  height: rem(67);
  padding: 0 1% rem(10);
}

.bannersNav li {
  flex: 1;
  padding: rem(0) rem(10);
  border-radius: 0 0 rem(10) rem(10);
  color: $red;
  font-size: rem(12);
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.bannersNav li:hover {
  box-shadow: 0 0.4rem 0.4143rem $gray-shaddow;
}

.bannersNavLink {
  display: flex;
  align-items: center;
  justify-content: center;
  height: rem(50);
  color: $brown;
  font-weight: 400;
  font-size: rem(10);
  line-height: rem(12);
  letter-spacing: rem(0.2);
}

.bannersNavLink:hover {
  color: $brown;
  text-decoration: none;
}

.bannersLink {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  color: transparent;
}

.bannersLink:hover {
  color: transparent;
}

.bannersBtn {
  @include mobile {
    display: none;
  }

  @include desktop {
    position: absolute;
    right: rem(16);
    bottom: rem(20);
    z-index: 2;
  }
}

.bannerImageContainer {
  position: relative;

  > div {
    display: block !important;
    margin: 0 auto !important;
  }
}
