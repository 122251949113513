@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.vehicles {
  width: 100%;
}

.box {
  @include mobile {
    display: none;
  }

  max-width: 85%;
  padding: rem(30) rem(19) rem(16.5) rem(19);
  border-radius: rem(5);
  margin: auto;
  margin-bottom: rem(34);
  background: $gray-light-3-1;
}

.column {
  padding-top: 0 !important;
}

.head {
  display: flex;
  padding-bottom: rem(16);
}

.headText {
  align-self: center;
  height: rem(19);
  padding-left: 15px;
  color: $red;
  font-weight: 900;
  font-size: rem(16);
  font-family: Lato, sans-serif;
  font-style: oblique;
  line-height: rem(19);
  text-transform: uppercase;
}

.title {
  width: rem(280);
  margin-bottom: 0;
  color: $brown;
  font-weight: 400;
  font-size: rem(20);
  line-height: rem(24);
  text-transform: uppercase;
}

.text {
  width: 100%;
  padding: rem(12) 0 0 0;
  font-weight: 500;
  font-size: rem(16);
  line-height: rem(19);
}

.searchWrapper {
  width: 100%;
}

.searchInputWrapper {
  width: 100%;
  height: rem(42);
  margin-top: rem(15);
}

.searchInputText {
  width: 100%;
  height: 100%;
  padding-right: rem(150);
  border: 0;
  border-radius: rem(50) !important;
  color: $brown;
  font-weight: 300;
  font-size: rem(13);
  font-style: oblique;
}

.searchInputButton {
  position: absolute;
  right: 0;
  z-index: 9;
  height: 42px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-left: rem(-50);
  background: $gray-light-3-1;
}

.btnAll {
  height: rem(42);
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.vignette {
  height: rem(169) !important;
  background: $light;
}

.vignette div:first-child {
  flex: 1.8;
}

.vignette div:last-child {
  flex: 1.2;
}

.vignette div:first-child div {
  position: unset !important;
}

.vignette h3 {
  font-size: rem(14);
}

.vignette a::before {
  border: 0;
}

.btnMobile {
  display: flex;
  align-items: center;
  max-width: rem(350);
  border-radius: rem(10);
  border: 1px solid $brown;
  margin: 0 auto;
  color: $brown;
  font-weight: 500;
  line-height: rem(17);
  white-space: unset;
  background: $light;
  fill: $brown;
}

.btnMobile i,
.btnMobile span {
  align-self: center;
}

.btnMobile span {
  max-width: 70%;
}

.mobileWrapper {
  justify-content: center;
  padding: 0 rem(15);
  margin: auto;
  margin-bottom: rem(42);
}

.btnContent {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
}

.btnIconRight {
  margin-left: rem(10);
  font-weight: 700;
}

.btnIconLeft {
  margin-right: rem(10);
  margin-left: rem(20);
}

.vignetteWrapper {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding-right: 0;
}

.viewAll {
  width: 100%;
  margin-bottom: 1rem !important;
  text-align: right;
}
