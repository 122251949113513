@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

@include mobile {
  .search {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(12);
  }

  .searchBouton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(16) rem(20) rem(10);
    border: 0;
    border-radius: rem(4);
    margin: 0 auto;
    background-color: $red;
  }

  .btnLeftIcon {
    margin-right: rem(10);
  }

  .btnRightIcon {
    margin-left: auto;
  }

  .searchBoutonText {
    max-width: 70%;
    color: $light;
    font-weight: 700;
    line-height: rem(17);
    text-align: left;
    text-transform: uppercase;
  }

  .searchCritizr {
    width: 85%;
    padding: rem(10) rem(20);
    border-top-left-radius: rem(5);
    border-top-right-radius: rem(5);
    margin: 0 auto;
    background: $gray-light;
  }

  .searchCritizr div {
    flex-wrap: wrap;
  }
}

.btnMobile {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: rem(350);
  min-height: rem(54);
  border-radius: rem(10);
  border: rem(1) solid $brown;
  margin: rem(14) auto 0 auto;
  color: $brown;
  font-weight: 500;
  line-height: rem(17);
  white-space: unset;
  background: $light;
  fill: $brown;
}

.btnIconLeft {
  margin-left: rem(20);
}

.btnText {
  flex: 1;
}
