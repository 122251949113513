@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.wrapper {
  position: relative;

  .autoCompleteMobileHeaderIcon,
  .searchIconWrapper,
  .iconEraseWrapper,
  .defaultItemWrapper {
    display: none;
  }
}

.wrapper .resultWrapper {
  @include desktop {
    position: absolute;
    top: rem(40);
    overflow-y: auto;
    z-index: 20;
    width: 100%;
    max-height: rem(200);
    border: rem(1) solid;
    background-color: $light;
  }
}

.wrapper .autocompleteList {
  padding: 0;
  margin: 0;
}

.autoCompleteItem.defaultItem {
  @include mobile {
    display: none;
  }
}

.wrapper .autoCompleteItem {
  padding: rem(15) rem(10);
  border-bottom: rem(1) solid;
  cursor: pointer;
}

.wrapper .autoCompleteItem:hover {
  background-color: $gray-medium;
}

.wrapper .autoCompleteItem:last-child {
  border-bottom: 0;
}

.wrapper.isOpen {
  @include mobile {
    position: fixed;
    z-index: 10000;
    padding-bottom: rem(14);
    background-color: $light;
    inset: 0;

    .autoCompleteMobileHeader {
      display: flex;
      align-items: center;
      padding: rem(14) rem(16) rem(14) rem(10);
      background-color: $gray-medium-4;
    }

    .autoCompleteMobileHeaderIcon {
      display: inline-block;
      margin-right: rem(10);
      font-size: rem(30);
      fill: $gray-dark-2;
    }

    .autoCompleteInput {
      @include mobile-xs {
        padding: rem(13) rem(11) rem(12) rem(12);
        line-height: rem(17);
      }

      @include mobile-s {
        padding: rem(10) rem(12) rem(11);
        font-size: rem(16);
        line-height: rem(19);
      }

      display: inline-block;
      width: calc(100% - #{rem(50)});
      border: 0;
      border-radius: rem(4);
      color: $brown;
      font-weight: 700;
    }

    .autocompleteList {
      overflow-y: auto;
      height: calc(100vh - #{rem(73)});
      background-color: $light;
    }

    .autoCompleteItem {
      padding-bottom: rem(13);
      padding-left: rem(22);
      border-bottom: rem(1) solid $brown;
      color: $brown;
    }

    .autoCompleteItem:not(:first-of-type) {
      padding-top: rem(17);
    }

    .autoCompleteItem mark {
      padding: 0;
      color: inherit;
      font-weight: 700;
      background-color: transparent;
    }

    .iconEraseWrapper {
      position: relative;
      display: inline-block;
      width: rem(24);
      height: rem(24);
      border: 0;
      border-radius: 100%;
      background-color: $gray-medium-5;
    }

    .defaultItemWrapper {
      position: relative;
      display: inline-block;
    }

    .searchEraseIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: rem(20);
      transform: translate(-50%, -50%);
      fill: $light;
    }

    .searchIconWrapper {
      position: relative;
      width: rem(36);
      height: rem(36);
      border-radius: rem(4);
      margin-left: auto;
      background-color: $red;
    }

    .searchIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      display: inline-block;
      font-weight: 500;
      font-size: rem(25);
      transform: translate(-50%, -50%);
      fill: $light;
    }
  }
}
