@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.search {
  @include desktop {
    padding: rem(20) rem(25);
  }

  position: relative;
  max-width: 75%;
  padding: rem(20) rem(16.5);
  border-radius: rem(5);
  margin: rem(-70) 0 rem(20) rem(230);
  background-color: $dark-1;
}

.searchWrapper {
  display: flex;
}

.iconLabel {
  display: flex !important;
  flex-direction: row;
  color: $gray-light-2 !important;
  cursor: pointer;
  fill: $gray-light-2 !important;
}

.searchItemTitle {
  overflow: hidden;
  align-self: center;
  max-width: rem(150);
  padding-left: rem(5);
  font-weight: 100;
  font-size: rem(13);
  line-height: rem(15);
  white-space: nowrap;
  text-transform: capitalize;
  text-overflow: ellipsis;

  &:hover {
    white-space: normal;
    text-overflow: clip;
  }
}

.searchItemIcon {
  display: inline-block;
  margin-top: rem(-8);

  path {
    fill: $light;
  }
}

.searchTitle {
  @include desktop {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
    font-size: rem(20);
  }

  padding-right: rem(15);
  margin: 0 auto rem(5);
  color: $light;
  font-weight: 500;
  font-size: rem(16);
  line-height: rem(19);
  text-align: left;
  text-transform: uppercase;
}

.searchFeedback {
  @include desktop {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }
}

.searchCritizr {
  justify-content: flex-end;

  div {
    @include desktop {
      flex-direction: column;
      align-items: flex-end !important;
    }

    flex-direction: row-reverse;
  }
}

.searchInputGroup {
  @include desktop {
    margin-top: rem(4);
  }

  height: rem(42);
  margin: rem(9) 0 rem(14) 0;
  font-style: italic;
}

.searchInputGroupText {
  height: 100%;
  border: 0;
  border-radius: rem(50);
  font-weight: 300;
}

.searchInputGroupText::placeholder {
  color: $brown;
  font-style: oblique;
}

.searchInputGroupButton {
  @include desktop {
    width: 200px;
  }

  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: rem(130);
  height: 100%;
  padding: 0.375rem 0.75rem;
  border: 0;
  border-radius: 50rem !important;
  margin-left: rem(-50);
  color: $light;
  background-color: $red;
}

.searchInputGroupIcon {
  font-size: rem(28);
  fill: $light;
}

.searchInputLabel {
  display: block;
  width: 100%;
}

.searchSteps {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: rem(63);
  padding: rem(15);
  background: $gray-light;
}

.searchStepsHome {
  position: absolute;
  left: 0;
  margin-top: rem(20);
}

.searchStepsItem {
  align-items: center;
  width: rem(250) !important;
}

.searchStepLabel {
  max-width: none;
  color: $brown;
  font-weight: 400;
  line-height: rem(17);
}

.searchServices {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: rem(20);
  margin-top: rem(22);
}

.searchLink {
  width: 20%;
}

.moreLink {
  position: relative;
  left: rem(10);
}

.searchItem {
  width: 100%;
  height: rem(15);
  cursor: pointer;
}

@include desktop-l-and-smaller {
  .searchLink:nth-child(n + 4) {
    display: none !important;
  }
}

@include mobile {
  .search {
    max-width: 90%;
    margin: 0 auto rem(30);
  }

  .searchLink {
    width: 33%;
  }
}

@include desktop {
  .searchLink {
    min-width: rem(120);
  }
}

@include desktop-md {
  .search {
    max-width: 90%;
    margin: rem(-40) auto rem(30);
  }

  .searchTitle {
    font-size: rem(14);
  }
}

@include desktop-l {
  .search {
    max-width: 85%;
    margin: rem(-70) auto rem(30);
  }

  .searchTitle {
    font-size: rem(16);
  }

  .searchLink {
    width: 25%;
  }

  .searchLink:nth-child(n + 5) {
    display: none !important;
  }
}
